<template>
	<div>

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-container>
      <b-card  title="Create role" class="card-icon mb-30 p-16">
        <b-row>
          <div class="col-md-12">
            <form metthod="post"
                  v-on:submit.prevent="validateBeforeSubmit">

              <div class="form-group">
                <label> <strong> Role name:</strong> </label> <br/>
                <input type="text"  v-model="role.role" name="role" class="form-control" required />
              </div>
              <br/>

              <!--<a class="float-right" v-if="role.permissions.length < all_permissions.length" style="font-weight: 700;" href="#" @click="selectAllPermissions">Select all</a>
              <a class="float-right" v-else style="font-weight: 700;" href="#" @click="unselectPermissions">Unselect</a>-->
              <table class="table table-bordered">
                <tr>
                  <th>Permission</th>
                  <th>View</th>
                  <th>Create</th>
                  <th>Edit</th>
                  <th>Delete</th>
                  <th>Reverse</th>
                  <th>Approve</th>
                  <th>Suspend</th>
                </tr>
                <tr v-for="permission in permissions">
                  <td>{{ permission.name }}</td>
                  <td><input type="checkbox" v-show="permission.view" class="form-control" name="view_role[]" v-model="role.permissions" :value="'View '+permission.name" /></td>
                  <td><input type="checkbox" v-show="permission.create" class="form-control" name="create_role[]" v-model="role.permissions" :value="'Create '+permission.name" /></td>
                  <td><input type="checkbox" v-show="permission.edit" class="form-control" name="edit_role[]" v-model="role.permissions" :value="'Edit '+permission.name" /></td>
                  <td><input type="checkbox" v-show="permission.del" class="form-control" name="delete_role[]" v-model="role.permissions" :value="'Delete '+permission.name" /></td>
                  <td><input type="checkbox" v-show="permission.reverse" class="form-control" name="delete_role[]" v-model="role.permissions" :value="'Reverse '+permission.name" /></td>
                  <td><input type="checkbox" v-show="permission.approve" class="form-control" name="delete_role[]" v-model="role.permissions" :value="'Approve '+permission.name" /></td>
                  <td><input type="checkbox" v-show="permission.suspend" class="form-control" name="delete_role[]" v-model="role.permissions" :value="'Suspend '+permission.name" /></td>
                </tr>
              </table>

              <div class="row">
                <div class="col-md-12">
                  <hr/>
                </div>
              </div>

              <div class="form-group">
                <button class="btn btn-success float-right" type="submit">
                   Save
                </button>
              </div>
            </form>
          </div>

        </b-row>
      </b-card>
    </b-container>
	</div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'

	export default {
		name: 'create-role',
		data () {
			return {
				role:{
					role:"",
					permissions:[]
				},
        isLoading: false,
        fullPage: true,
        all_permissions:["Reverse Loyalty","Approve Loyalty","Suspend Outlets","Suspend Customers","View Loyalty","View Users","Create Users","Edit Users","Delete Users","View Outlets","Create Outlets","Edit Outlets","Delete Outlets", "View Lottery","Create Lottery","Edit Lottery","Delete Lottery","View Customers","Create Customers","Edit Customers","Delete Customers","View Campaigns","Create Campaigns","Edit Campaigns","Delete Campaigns","View Reports","View Settings","Create Settings","Edit Settings","Delete Settings"],
				permissions:[
					{
						name: "Loyalty",
						view: true,
						create: false,
						edit: false,
						del: false,
						reverse: true,
						approve:true,
            suspend:false
					},
					{
						name: "Users",
						view: true,
						create: true,
						edit: true,
						del: true,
						reverse: false,
						approve:false,
						suspend:false
					},
					{
						name: "Outlets",
						view: true,
						create: true,
						edit: true,
						del: true,
						reverse: false,
						approve:false,
            suspend:true
					},
          {
            name: "Lottery",
            view: true,
            view_own: true,
            create: true,
            edit: true,
            del: true,
            reverse: false,
            approve:false,
            suspend:false
          },
					{
						name: "Customers",
						view: true,
						view_own: true,
						create: true,
						edit: true,
						del: true,
						reverse: false,
						approve:false,
            suspend:true
					},

					{
						name: "Campaigns",
						view: true,
						create: true,
						edit: true,
						del: true,
						reverse: false,
						approve:false,
						approve_pending_fraud:false
					},
					{
						name: "Reports",
						view: true,
						create: false,
						edit: false,
						del: false,
						reverse: false,
						approve:false,
						approve_pending_fraud:false
					},
					{
						name: "Settings",
						view: true,
						create: true,
						edit: true,
						del: true,
						reverse: false,
						approve:false,
						approve_pending_fraud:false
					},
          {
            name: "Invoice",
            view: true,
            create: true,
            edit: true,
            del: true,
            reverse: false,
            approve:false,
            approve_pending_fraud:false
          }

				],
			}
		},

		computed: {

		},
		mounted (){
      this.getRoleDetails();
		},
		components: {
      Loading
		},
		methods: {

      ...mapActions(["getRoleById","updateRole"]),

      selectAllPermissions(){
        console.log("Clicked");
        this.role.permissions = this.all_permissions;
      },
      unselectPermissions(){
        this.role.permissions = [];
      },

			validateBeforeSubmit(){

				let self = this;

				this.$validator.validateAll().then((result) => {
					if (result){
						self.saveRole();
					}

				});

			},
      getRoleDetails(){
        let self = this;
        this.getRoleById({id: this.$route.params.id})
          .then((role) => {


            this.role = role;
            this.role.permissions = [];

            let permissions = role.roleHasPermissions;

            permissions.forEach(function (permission) {
              self.role.permissions.push(permission.permission.permissionName);
            });

            delete this.role.roleHasPermissions;

          })
          .catch(error => {


          })
      },
			saveRole(){

        let self = this;

        this.isLoading = true;


				this.updateRole( this.role)
						.then(() => {

              self.isLoading = false;

              self.$bvToast.toast("Role updated.", {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false
              });

              this.$router.push("/app/pages/settings");
							//this.$router.go(-1);


						})
						.catch(error => {

              self.isLoading = false;


              self.$bvToast.toast(error.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });

						})
			}

		}
	}
</script>

